
import { project, Tool, Point, Color, Size, Rectangle } from 'paper';
import { EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as paper from 'paper';
import { Observable, Subscriber, Subject, zip } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import * as moment from 'moment';
import * as _ from 'lodash';
//import * as Hammer from 'hammerjs';

import { IdeaService }  from '../idea.service';
import { SequenceService }  from '../sequence.service';
import { Sequence }  from '../sequence';
import { SequenceEngineService }  from '../sequence-engine.service';
import { TextChangedService }  from '../text-changed.service';
import { Idea }  from '../idea';
import { Stream }  from '../stream';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Me }  from '../me';
import { MeService }  from '../me.service';
import { Site }  from '../site';
import { StreamService }  from '../stream.service';
import { SettingsService }  from '../settings.service';
import { StreamsDialogComponent } from '../streams-dialog/streams-dialog.component';
import { IconService }  from '../icon.service';
import { SocketService }  from '../socket.service';
import { SearchQueryService }  from '../search-query.service';
import { SearchQuery }  from '../search-query';
import { QrDialogComponent } from '../qr-dialog/qr-dialog.component';
import { ShowIdeaService }  from '../show-idea.service';

import { Canvas } from './canvas';
import { Box } from './box';
import { ToolPalette }  from './palette/tool-palette';
import { TimeAxisBase }  from './time-axis-base';
import { TimeAxisVert }  from './time-axis-vert';
import { TimeAxis }  from './time-axis';
import { StreamObjectBase }  from './stream-object-base';
import { StreamObject }  from './stream-object';
import { StreamObjectVert }  from './stream-object-vert';
import { IdeaState }  from './idea-object';
import { IdeaPopup }  from './idea-popup';
import { TimeRef }  from './time-ref';
import { ReorderHelper }  from './reorder-helper';
import { Text }  from './text';
import { HelpLayer }  from './help-layer';
import { LegendPalette }  from './palette/legend-palette';
import { ToolbarButton }  from './button/toolbar-button';
import { LegendButton }  from './button/legend-button';

import { Nameable }  from './interface/nameable';
import { Selectable, instanceOfSelectable }  from './interface/selectable';
import { Clickable, instanceOfClickable }  from './interface/clickable';
import { Dragable, instanceOfDragable }  from './interface/dragable';
import { Targetable, instanceOfTargetable }  from './interface/targetable';
import { Overable, instanceOfOverable }  from './interface/overable';
import { Deletable, instanceOfDeletable }  from './interface/deletable';
import { Editable, instanceOfEditable }  from './interface/editable';
import { DblClickable, instanceOfDblClickable }  from './interface/dblclickable';
import { Reorderable }  from './interface/reorderable';
import { Resettable }  from './interface/resettable';

export class Editor extends Canvas implements Reorderable, Resettable {

  private toolPalette: ToolPalette = null;
  private textInput: HTMLInputElement;
  private textField = null;
  private dialog: MatDialog;
  private clipboard: Clipboard;
  private breakpointObserver: BreakpointObserver;
  private streamobjs: StreamObjectBase[] = [];
  private streams: Stream[] = [];
  private ideas: Idea[] = [];
  private sequences: Sequence[] = [];
  private rohobjs: paper.Item[] = [];
  private me: Me;
  private allowReorder = false;
  private sockets = true;
  private roh: ReorderHelper;
  private searchTerms = new Subject<string>();
  private popup: IdeaPopup;
  private helpLayer: HelpLayer;
  private _hideHelp: boolean;
  private legendPalette: LegendPalette = null;
  private toolbarButton: ToolbarButton = null;
  private legendButton: LegendButton = null;
  private map = false;
  private currZoom: string;
  private horizontal = true;
  private top = 0;
  private zoomWidget: paper.Item;
  private zoomAmount = 0;

  site: Site;
  tref: TimeRef;
  timeAxis: TimeAxisBase = null;
  _size: paper.Size;
  time = 0;

  static MAX_STREAMS: number  = 50;
  static MAX_STREAMS_LATEST: number  = 10;

  constructor(
    dialog: MatDialog,
    clipboard: Clipboard,
    breakpointObserver: BreakpointObserver,
    private ideaService: IdeaService,
    private iconService: IconService,
    private streamService: StreamService,
    private sequenceService: SequenceService,
    private sequenceEngineService: SequenceEngineService,
    private settingsService: SettingsService,
    private socketService: SocketService,
    private textChangedService: TextChangedService,
    private searchQueryService: SearchQueryService,
    private showIdeaService: ShowIdeaService,
    private meService: MeService,
    textInput: HTMLInputElement,
    me: Me,
    site: Site,
    sockets: boolean,
    map: boolean
  )
  {
    super();
    this.me = me;
    this.site = site;
    this.dialog = dialog;
    this.clipboard = clipboard;
    this.breakpointObserver = breakpointObserver;
    this.textInput = textInput;
    this.tref = new TimeRef();
    this.sockets = sockets;
    this.map = map;

    this.searchQueryService.queryChanged$.subscribe(change => {
      if (change.useIdeas) {
        this.zoomTo("hour", false);
        this.searchIdeas(change);
      }
      else {
        if (typeof(change.text) == "string") {
          this.searchTerms.next(change.text);
        }
        else if (typeof(change.opened) == "boolean") {
          if (change.opened) {
            if (change.latest) {
              this.zoomTo("week", true);
            }
            else if (change.now) {
              this.zoomTo("hour", true);
            }
            else {
              if (this.currZoom) {
                this.zoomTo(this.currZoom, true);
              }
              this.streams = [];
              this.refreshStreams();
            }
          }
          else {
            this.endSearch();
          }
        }
      }
    });
    this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(term => {
      this.initTop();
      if (term) {
        this.streamService.searchStreams(term, Editor.MAX_STREAMS).subscribe(streams => {
          this.setStreams(streams);
        });
      }
      else {
          this.zoomTo("week", true);
      }
    });

  }

  private refreshStreams() {
    this.setStreams(this.streams);
  }

  private isSmall(): boolean {
    return this.breakpointObserver.isMatched("(max-width: 960px)");
  }

  private calcInitTop(): number {
    return this.horizontal ? TimeAxis.TOP : TimeAxisVert.LEFT;
  }

  private initTop() {
    this.top = this.calcInitTop();
  }

  private searchIdeas(query: SearchQuery) {

    this.initTop();
    var end = moment(query.date).clone();
    end.add(query.range, "seconds");

    // always get all ideas for now.
    this.streamService.searchStreamIdeas(query.text ? query.text : "", query.date, end.format(), this.site.timelineShowAllStreams, false).subscribe(result => {
      result = this.limitStreamsAndIdeas(result);
      result.streams.forEach(s => s.temp = true);
      this.setStreamsAndIdeas(result.streams, result.ideas);
    });
  }

  private endSearch() {
    this.setSettingsStreams();
  }

  load(size: paper.Size): void {

    this._size = size;
    var small = this.isSmall();

    this.top = this.horizontal ? TimeAxis.TOP : TimeAxisVert.LEFT;
    this.roh = new ReorderHelper(this, 0, new Point(this.horizontal ? 0 : TimeAxisVert.LEFT, this.horizontal ? TimeAxis.TOP : 0));

    if (this.zoomWidget) {
      this.zoomWidget.remove();
      this.zoomWidget = null;
    }
    this.zoomWidget = new paper.Path.Circle(new Point(100, 100), 80);
    this.zoomWidget.strokeColor = new Color('grey');
    this.zoomWidget.strokeWidth = 8;
    this.zoomWidget.visible = false;

    if (this.toolPalette) {
      this.toolPalette.release();
      this.toolPalette.remove();
      this.toolPalette = null;
    }
    this.toolPalette = new ToolPalette(this.site);
    this.toolPalette.rebuild(new Point(size.width-ToolPalette.WIDTH-30, 60), new Size(0, 0));
    this.toolPalette.visible = !small;
    paper.project.view.onFrame = (event) => {
      this.time = event.time;
      if (this.timeAxis) {
        this.timeAxis.animate(event.time);
      }
    }

    let v = paper.view as any;
    v.onResize = (event) => {
      this._size = new Size(event.size.width + 4, event.size.height + 4);
      this.rebuildAxis();
    }

    this.helpLayer = new HelpLayer();

    if (this.toolbarButton) {
      this.toolbarButton.release();
      this.toolbarButton.remove();
      this.toolbarButton = null;
    }
    this.toolbarButton = new ToolbarButton();
    this.toolbarButton.rebuild(new Point(size.width-40, size.height-40), new Size(0, 0));
    this.toolbarButton.visible = small;

    if (this.legendButton) {
      this.legendButton.release();
      this.legendButton.remove();
      this.legendButton = null;
    }
    this.legendButton = new LegendButton();
    this.legendButton.rebuild(new Point(40, size.height-40), new Size(0, 0));
    this.legendButton.visible = false;

    this.removeStreams();
    this.streamobjs = [];
    this.streams = [];

    this.settingsService.getSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(settings => {

 //     this._hideHelp = settings.hideHelp;
      this._hideHelp = true;

      if (settings.start == null && settings.end == null) {
        this.startSearch();
      }
      else {
        if (settings.top) {
          this.top = settings.top;
        }
        else {
          this.initTop();
        }
        this.tref.start = moment(settings.start);
        this.tref.end = moment(settings.end);
        this.tref.validate();
        this.setSettingsStreams()
      }

    });

  }

  public resize(size: paper.Size) {
    this._size = new Size(size.width + 4, size.height + 4);
    this.rebuildAxis();
    this._setStreamsAndIdeas(this.streams, this.ideas, []);
  }

  public showHelp() {
    this.helpLayer.showHelp();
    this.updateViewSettings().subscribe(() => {});
  }

  public hideHelp() {
    this.helpLayer.hideHelp();
    this.updateViewSettings().subscribe(() => {});
  }

  public searchLatest() {
    this.searchQueryService.latest();
  }

  public searchNow() {
    this.searchQueryService.now();
  }

  public closePalette(title: string) {
    if (title == "Zoom") {
      this.toolPalette.visible = false;
      this.toolbarButton.visible = true;
    }
    else if (title = "Legend") {
      this.legendPalette.visible = false;
      this.legendButton.visible = true;
    }
  }

  public showLegend() {
    this.legendPalette.visible = true;
    this.legendButton.visible = false;
  }

  public showToolbar() {
    this.toolPalette.visible = true;
    this.toolbarButton.visible = false;
  }

  private startSearch() {
    if (this.site.timelineInitialZoom) {
      this.currZoom = this.site.timelineInitialZoom;
      this.searchQueryService.open();
    }
    else if (this.site.useNowForTimeline) {
      this.searchQueryService.now();
    }
    else if (this.site.timelineShowAllStreams) {
      this.searchQueryService.open();
    }
    else {
      this.searchQueryService.latest();
    }
    this.updateViewSettings().subscribe(() => {});
  }
/*
  public removeStream(stream: Stream, refresh: boolean) {
    this.settingsService.getSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(settings => {
      if (settings.streams) {
        settings.streams = settings.streams.filter(e => e._id != stream._id && stream.favorite);
        this.updateStreamSettingsWithStreams(settings.streams).subscribe(() => {
          if (refresh) {
            this.streams = this.streams.filter(e => e._id != stream._id);
            this.refreshStreams();
          }
        });
      }
    });
  }

  public addStream(stream: Stream) {
    this.settingsService.getSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(settings => {
      if (!settings.streams || settings.streams.filter(e => e._id == stream._id).length == 0) {
        if (!settings.streams) {
          settings.streams = [];
        }
        settings.streams.push(stream);
        this.updateStreamSettingsWithStreams(settings.streams).subscribe(() => {});
      }
    });
  }
*/
  private getAllStreams(ids: string[]): Observable<any[]> {

    // don't report security errors we filter them out.
    return zip(...ids.map(e => this.streamService.getStreamNoSecurityError(e)));

  }

  private fixStreams(streams: Stream[], sstreams: Stream[]) {

    // bad security returns a null stream.
    sstreams = sstreams.filter(e => e != null);

    streams.forEach(s => {
      var ss = sstreams.filter(e => e._id == s._id);
      if (ss.length > 0) {
        s.name = ss[0].name;
        s.image = ss[0].image;
        s.emoji = ss[0].emoji;
        s.icon = ss[0].icon;
      }
      else {
        s.name = "[hidden]";
      }
    });

  }

  private setSettingsStreams() {

    this.settingsService.getSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(settings => {
      if (settings.streams) {
        this.getAllStreams(settings.streams.map(e => e._id)).subscribe(streams => {
          this.fixStreams(settings.streams, streams);
          this._setStreams(settings.streams, settings.streams);
        });
      }
      else {
        this.searchQueryService.open();
      }
     });

  }

  private setStreams(streams: Stream[]) {

    this.settingsService.getSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(settings => {
      this._setStreams(streams, settings.streams);
     });

  }

  private _setStreams(streams: Stream[], favstreams: Stream[]) {

    this.tref.validate();

    if (favstreams) {
      streams.forEach(s => {
        s.favorite = favstreams.find(e => e._id == s._id) != null;
      });
    }
    if (this.site.expandAllTimelineStreams) {
      streams.forEach(s => s.open = true);
    }
    this.removeStreams();
    this.streamobjs = [];
    this.rohobjs = [];
    if (streams) {
      this.addStreams(streams);
      // and sort by home.
      this.streams = this.streams.sort((i1, i2) => {
        return i1.home > i2.home ? 1 : (i1.home < i2.home ? -1 : 0);
      });
      this.loadIdeas();
      var ids = streams.map(e => e._id);
      ids.push("streams");
      this.socketService.setStreams(ids);
      this.socketService.openDocs();
    }
    else {
      this.streams = [];
    }

  }

  private _setStreamsAndIdeas(streams: Stream[], ideas: Idea[], favstreams: Stream[]) {
    if (favstreams) {
      streams.forEach(s => {
        s.favorite = favstreams.find(e => e._id == s._id) != null;
      });
    }
    if (this.site.expandAllTimelineStreams) {
      streams.forEach(s => s.open = true);
    }
    this.removeStreams();
    this.streamobjs = [];
    this.rohobjs = [];
    this.sequences = [];
    if (streams) {
      this.addStreams(streams);
      this.streams = streams;
      this.removeIdeas();
      this.getAllSequences(ideas).subscribe(seqs => {
        this.addUniqueSeqs(seqs as Sequence[]);
        this.setIdeas(ideas);
      });
    }
    else {
      this.streams = [];
    }
  }

  private setStreamsAndIdeas(streams: Stream[], ideas: Idea[]) {

    this.settingsService.getSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(settings => {
      this._setStreamsAndIdeas(streams, ideas, settings.streams);
    });
  }
/*
  public chooseStreams() {
    this.dialog.open(StreamsDialogComponent, { data: { streams: this.streams, site: this.site } }).afterClosed().subscribe(result => {
      if (result) {
        this.top = TimeAxis.TOP;
        result.streams.forEach(s => s.open = true);
        this.setStreams(result.streams);
        this.updateStreamSettings().subscribe(() => {});
      }
    });
  }
*/
  private validateStartEnd() {
    let duration = moment.duration(this.tref.end.diff(this.tref.start));
    if (duration.asSeconds() < 2) {
      this.tref.start = this.tref.start.subtract(1, 'seconds');
      this.tref.end = this.tref.end.add(1, 'seconds');
      this.updateViewSettings().subscribe(() => {});
    }
  }

  private setIdeas(ideas: Idea[]) {

    this.validateStartEnd();
    this.tref.setRange();
    this.rebuildAxis();

    // make sure all the ideas are removed from the streams
    this.streamobjs.forEach(s => s.removeIdeas());

    if (ideas.length > 0) {
      this.streamobjs.forEach(s => s.addIdeas(ideas.filter(e => e.stream == s.stream._id)));
      ideas.forEach(e => this.ideas.push(e));
    }
    if (!this._hideHelp) {
      this.helpLayer.showHelp();
    }
    this.layoutStreams();
    this.buildLegend();
  }

  private buildLegend() {

    var context =  this.sequenceEngineService.buildContext(null, this.me);
    var legends = [];
    for (var i=0; i<this.sequences.length; i++) {
      var s = this.sequences[i];
      if (s.views && s.views.all && s.views.all.legend) {
        this.sequenceEngineService.extend(s);
        let legend = (s as any)._execFunc(s.views.all.legend, { context: context });
        if (legend && legend.data) {
          legends.push({ name: s.name, data: legend.data });
        }
      }
    }

    if (this.legendPalette) {
      this.legendPalette.release();
      this.legendPalette.remove();
      this.legendPalette = null;
    }
    if (legends.length > 0) {
      this.legendPalette = new LegendPalette(legends);
      this.legendPalette.rebuild(new Point(30, this._size.height-this.legendPalette.calcHeight()-30), new Size(0, 0));
      this.legendPalette.visible = !this.isSmall();
    }
  }

  private getAllSequences(ideas: any[]): Observable<any[]> {

    var seqs = _.uniq(ideas.map(e => e.sequence)).filter(e => e != null && e != "");
    var observables = seqs.map(e => this.sequenceService.getSequenceNoSecurityError(e));
    observables.push(this.sequenceService.getSequenceNoSecurityError("default"));

    return zip(...observables);
  }

  public getSequence(id: string): Sequence {
    if (id == "") {
      var seq = this.sequences.find(e => e.name == "default-conversations");
      if (seq) {
        return seq;
      }
      console.log("need default", this.sequences);
      return new Sequence();
    }
    else {
      var seqs = this.sequences.filter(e => e._id == id);
      if (seqs.length > 0) {
        return seqs[0];
      }
      console.log("no sequence found");
      return new Sequence();
    }
  }

  public addIdea(idea: Idea) {

//    console.log("add idea", idea._id);

    let stream = this.streamobjs.find(e2 => e2.stream._id == idea.stream);
    if (stream) {
      var seqs = this.sequences.filter(e => e && e._id == idea.sequence);
      if (seqs.length > 0) {
        if (stream.addOneIdea(idea)) {
          this.ideas.push(idea);
        }
      }
      else {
        this.sequenceService.getSequenceNoSecurityError(idea.sequence).subscribe(sequence => {
          this.sequences.push(sequence);
          if (stream.addOneIdea(idea)) {
            this.ideas.push(idea);
          }
        });
      }
    }

  }

  private loadFavorites() {
    this.removeIdeas();
    this.sendDaterange();
    let start = this.tref.start.format();
    let end = this.tref.end.format();
    // we show ALL ideas for now, ignoring the map setting.
    this.ideaService.getIdeas(start, end,
      this.streams.map(e => e._id), false).subscribe(ideas => {
      if (ideas.body.length > 0) {
        var idealist = ideas.body;
        this.getAllSequences(idealist).subscribe(seqs => {
          this.addUniqueSeqs(seqs as Sequence[]);
          this.setIdeas(idealist);
        });
      }
      else {
        this.setIdeas([]);
      }
    });
  }

  private limitStreamsAndIdeas(result: any): any {

      if (result.streams && (result.streams.length > Editor.MAX_STREAMS)) {
        // sort by date.
        result.streams = result.streams.sort((i1, i2) => {
          if (i1.modifyDate > i2.modifyDate) {
            return -1;
          }
          if (i1.modifyDate < i2.modifyDate) {
            return 1;
          }
          return 0;
        });
        result.streams = result.streams.slice(0, Editor.MAX_STREAMS);
        var streamids = result.streams.map(e => e._id);
        result.ideas = result.ideas.filter(e => streamids.indexOf(e.stream) >= 0);
      }
      // and sort by home.
      result.streams = result.streams.sort((i1, i2) => {
        return i1.home > i2.home ? 1 : (i1.home < i2.home ? -1 : 0);
      });

      return result;
  }

  private loadAll() {
    this.removeIdeas();
    // always get all ideas for now.
    this.streamService.searchStreamIdeas("", this.tref.start.format(), this.tref.end.format(), this.site.timelineShowAllStreams, false).subscribe(result => {
      result = this.limitStreamsAndIdeas(result);
      result.streams.forEach(s => {
        s.temp = true;
      });
      this.setStreamsAndIdeas(result.streams, result.ideas);
    });
  }

  private loadIdeas() {
    if (this.streams.length > 0 && !this.map) {
      this.loadFavorites();
    }
    else {
      this.loadAll();
    }
  }

  private updateViewSettings(): Observable<any> {
    this.tref.validate();
    return this.settingsService.updateSettings(this.me, this.site.domain ? this.site.domain : "timeline", {
      start: this.tref.start.format(),
      end: this.tref.end.format(),
      top: this.top,
      hideHelp: !this.helpLayer.helpShowing()
    });
  }
/*
  private updateStreamSettingsWithStreams(streams: Stream[]): Observable<any> {
    return this.settingsService.updateSettings(this.me, this.site.domain ? this.site.domain : "timeline", {
      streams: streams
    });
  }

  public updateStreamSettings(): Observable<any> {
    return this.updateStreamSettingsWithStreams(this.streams);
  }
*/
  private removeStreams() {
    this.streamobjs.forEach(i => {
      i.release();
      i.remove();
    });
    this.rohobjs.forEach(i => {
//      i.release();
      i.remove();
    });
  }

  private addStreams(streams: Stream[]) {

    streams.forEach(s => {
      let cls = this.horizontal ? StreamObject : StreamObjectVert;
      let stream = new cls(this, s, this.me, this.helpLayer,
        this.textChangedService,
        this.ideaService,
        this.iconService,
        this.sequenceService,
        this.sequenceEngineService);
      stream.visible = false;
      stream.rebuild(new Point(0, 0), new Size(this._size.width, this._size.height));
      stream.addHelp();
      this.streamobjs.push(stream);
    });
    this.roh.build(this.rohobjs, this._size.width, this.streamobjs);

  }

  public doLayout() {
    if (this.horizontal) {
      var top = this.top;
      this.streamobjs.forEach(s => {
        s.moveTo(new Point(s.bounds.x, top));
        top += s.size().height;
      });
    }
    else {
      var top = this.top;
      this.streamobjs.forEach(s => {
        s.moveTo(new Point(top, s.bounds.y));
        top += s.size().width;
      });
    }
  }

  public layoutStreams() {
    var top = this.top;
    this.streamobjs.forEach(s => {
      if (this.horizontal) {
        s.moveTo(new Point(s.bounds.x, top));
      }
      else {
        s.moveTo(new Point(top, s.bounds.y));
      }
      s.bringToFront();
      s.setLayout();
      s.visible = true;
      top += this.horizontal ? s.size().height : s.size().width;
    });
    this.bringAllToFront();
  }

  private removeIdeas() {
    this.streamobjs.forEach(e => e.removeIdeas());
    this.ideas = [];
    this.sequences = [];
  }

  private rebuildAxis() {
    if (this.timeAxis) {
      this.timeAxis.release();
      this.timeAxis.remove();
      this.timeAxis = null;
    }
    if (this.horizontal) {
      this.timeAxis = new TimeAxis(this.tref);
    }
    else {
      this.timeAxis = new TimeAxisVert(this.tref);
    }
    this.timeAxis.rebuild(new Point(0, 0), new Size(this._size.width, this._size.height));
  }

  public zoomIn() {
    let dist = moment.duration(moment.duration(this.tref.end.diff(this.tref.start)) / 4);
    this.tref.start = moment(this.tref.start + dist);
    this.tref.end = moment(this.tref.end - dist);
    this.validateStartEnd();
    this.updateViewSettings().subscribe(() => {
      this.loadIdeas();
    });
  }

  public zoomOut() {
    let dist = moment.duration(this.tref.end.diff(this.tref.start));
    if (dist.asYears() < 20) {
      this.tref.start = moment(this.tref.start - dist);
      this.tref.end = moment(this.tref.end + dist);
      this.validateStartEnd();
      this.updateViewSettings().subscribe(() => {
        this.loadIdeas();
      });
    }
  }

  public zoomTo(scale: string, reload: boolean) {
    this.currZoom = scale;
    var now = this.site.today ? moment(this.site.today) : moment();
    if (scale == "hour") {
      this.tref.start = now.startOf("hour");
      this.tref.end = this.tref.start.clone();
      this.tref.start.subtract(14, "hours");
      this.tref.end.add(12, "hours");
    }
    else if (scale == "day") {
      this.tref.start = now.startOf("day");
      this.tref.end = this.tref.start.clone();
      this.tref.start.subtract(1, "hours");
      this.tref.end.add(25, "hours");
    }
    else if (scale == "week") {
      this.tref.start = now.startOf("week");
      this.tref.end = this.tref.start.clone();
      this.tref.start.subtract(4, "days");
      this.tref.end.add(6, "days");
    }
    else if (scale == "month") {
      this.tref.start = now.startOf("month");
      this.tref.end = this.tref.start.clone();
      this.tref.start.subtract(6, "days");
      this.tref.end.add(31, "days");
    }
    else if (scale == "year") {
      this.tref.start = now.startOf("year");
      this.tref.end = this.tref.start.clone();
      this.tref.start.subtract(1, "months");
      this.tref.end.add(13, "months");
    }
    this.validateStartEnd();
    this.updateViewSettings().subscribe(() => {
      if (reload) {
        this.loadAll();
      }
    });
  }

  public copyJSONToClipboard(json: any) {
    this.clipboard.copy(JSON.stringify(json, null, 2));
  }

  public bringAllToFront() {
	  this.streamobjs.forEach(s => s.bringAllToFront());
	  this.toolPalette.bringToFront();
	  this.helpLayer.bringToFront();
	  if (this.legendPalette) {
	    this.legendPalette.bringToFront();
	  }
 	  this.toolbarButton.bringToFront();
 	  this.legendButton.bringToFront();
 }

  public testEdit(field: string, text: string) {
    this.textChangedService.changed(field, text);
  }

  public finishEdit() {
    if (this.textInput.style.display == "block") {
      this.textInput.style.display = "none";
      this.textChangedService.changed(this.textField, this.textInput.value);
    }
  }

  public editString(rect: paper.Rectangle, field: string, text: string, bold: boolean = false, size: string = "14px") {

    this.textField = field;
    this.textInput.style.display = "block";
    this.textInput.style.top = rect.y + "px";
    this.textInput.style.left = rect.x + "px";
    this.textInput.style.height = rect.height + "px";
    this.textInput.style.width = rect.width + "px";
    this.textInput.style.fontWeight = bold ? "bold" : "normal";
    this.textInput.style.fontSize = size;
    this.textInput.style.background = "transparent";
    this.textInput.value = text;
    this.textInput.select();

  }

  public setSelection(selectable: Selectable) {
    super.setSelection(selectable);
    this.toolPalette.setSelection(this, selectable);
  }

  public makeQR(idea: string, varname: string) {
    this.ideaService.getQR(idea, varname).subscribe(data => {
      this.dialog.open(QrDialogComponent, { data: data.data }).afterClosed().subscribe();
    });
  }

  public showIdea(idea: string) {
    this.showIdeaService.show(idea);
  }

  public rotate() {
    this.horizontal = !this.horizontal;
    this.load(this._size);
  }

  private sendDaterange() {
    if (this.me.name == "public") {
      if (window.parent) {
        console.log("sending date range");
        window.parent.postMessage(JSON.stringify({ msg: "daterange", start: this.tref.start.format(), end: this.tref.end.format() }), "*");
      }
    }
    else {
      this.meService.dateRange(this.tref.start.format(), this.tref.end.format()).subscribe(() => {});
    }
  }

  private addUniqueSeqs(seqs: Sequence[]) {
    seqs.forEach(e => {
      if (this.sequences.find(e2 => e2._id == e._id) == null) {
        this.sequences.push(e);
      }
    });
  }

  private loadMore(start, end) {

    // always get all ideas for now.
    this.streamService.searchStreamIdeas("", start.format(), end.format(), this.site.timelineShowAllStreams, false).subscribe(result => {

      result = this.limitStreamsAndIdeas(result);
      if (result.ideas.length > 0) {
        var idealist = result.ideas;
        this.getAllSequences(idealist).subscribe(seqs => {
          this.addUniqueSeqs(seqs as Sequence[]);
          idealist.forEach(e => {
            let stream = this.streamobjs.find(e2 => e2.stream._id == e.stream);
            if (stream) {
              if (stream.addOneIdea(e)) {
                this.ideas.push(e);
              }
            }
            else {
              let stream = result.streams.find(e2 => e2._id == e.stream);
              this.addStreams([stream]);
              this.ideas.push(e);
            }
          });
          this.rebuildAxis();
          this.layoutStreams();
        });
      }
    });
  };

  public startPanning() {
    this.tref.startPanning();
  }

  public pan(offset: paper.Size) {

    this.top += this.horizontal ? offset.height : offset.width;

    this.streamobjs.forEach(s => s.pan(offset));
    this.timeAxis.pan(offset);
  }

  private snapToBounds(): boolean {

    var didSnap = false;
    if (this.streamobjs.length > 0) {
      // snap to bottom.
      let last = this.streamobjs[this.streamobjs.length-1];
      let diff = this._size.height - (last.bounds.y + last.bounds.height);
      if (diff > 0) {
        this.top = this.top + diff;
        didSnap = true;
      }
    }

    if (this.top > this.calcInitTop()) {
      // snap to top
      this.initTop();
      didSnap = true;
    }

    return didSnap;

  }

  public endPan() {

    var didSnap = this.snapToBounds();

    this.sendDaterange();
    this.updateViewSettings().subscribe(() => {

      let more = this.tref.endPan((start, end) => {
        this.loadMore(start, end);
      });
      if (!more || didSnap) {
        this.rebuildAxis();
        this.layoutStreams();
      }
    });
  }

  public startZooming(position: paper.Point) {
    this.zoomWidget.position = position;
    this.zoomWidget.visible = true;
    this.zoomWidget.applyMatrix = false;
    this.zoomWidget.bringToFront();
    this.zoomAmount = 1;
  }
  public zoom(scale: number) {
    this.zoomWidget.scale(scale - (1 - this.zoomAmount));
    this.zoomAmount = scale;
  }
  public endZoom() {
    this.zoomWidget.visible = false;
    this.zoomWidget.matrix.reset();
    if (this.zoomAmount > 1) {
      this.zoomIn();
    }
    else if (this.zoomAmount < 1) {
      this.zoomOut();
    }
  }

  public popupIdea(stream: Stream, idea: Idea, bounds: paper.Rectangle, position: paper.Point) {
    if (this.popup && this.popup.idea._id == idea._id) {
      return;
    }
    this.closePopup();
    this.popup = new IdeaPopup(this, idea);
	  let left = bounds.left;
	  if (left <= 0) {
	    left = position.x;
	  }
    this.popup.rebuild(new Point(left + 20, bounds.top + bounds.height + 20), new Size(IdeaPopup.WIDTH, 100));
    this.setSelection(this.popup);
  }

  public closePopup() {
    if (this.popup) {
      this.popup.release();
      this.popup.remove();
    }
  }

  public dblClick(point: paper.Point) {
    this.streamobjs.forEach(s => {
      if (s.contains(point)) {
        s.toggleExpand();
        return;
      }
    });
  }

  public expandAll() {
    this.streamobjs.forEach(s => {
      s.doExpand();
    });
  }

  public contractAll() {
    this.streamobjs.forEach(s => {
        s.doContract();
    });
  }

	public saveExpanded(title: string, state: boolean) {
	}
	public saveLocation(title: string, loc: paper.Point) {
	}

  // Reorderable
  getBounds(): paper.Rectangle {
    return new Rectangle(new Point(0, 0), this._size);
  }

  public draggingStarted() {
//    this.allowReorder = this.streamobjs.filter(e => !e.stream.favorite).length == 0;
    this.allowReorder = false;
  }

  public reorder(drag: number, over: number) {
/*
    if (this.allowReorder) {
      this.roh.reorder(drag, over);
    }
*/
  }

  public endReorder() {
/*
    if (this.roh.didReorder() && this.allowReorder) {
      this.roh.endReorder(this.streams);
      this.updateStreamSettings().subscribe(() => {});
      this.refreshStreams();
    }
*/
  }

  // Resettable
  public reset(canvas: Canvas) {
    this.dialog.open(ConfirmComponent, { data: { title: "Reset", description: "Are you sure you wish to discard all your settings? You will be asked to choose your streams again" } }).afterClosed().subscribe(result => {
      if (result) {
        this.settingsService.clearSettings(this.me, this.site.domain ? this.site.domain : "timeline").subscribe(() => {
          this.initTop();
          this.tref.start = null;
          this.tref.end = null;
          this.tref.validate();
          this.removeStreams();
          this.streams = [];
          this.startSearch();
        });
      }
    });
  }

}
